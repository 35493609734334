import Image from 'next/legacy/image';
import Link from 'next/link';
import type { AdvertStoryblok } from 'component-types-sb';

import styles from '../../styles/Advert.module.css';

export default function Advert({ link, image, cta_title }: AdvertStoryblok) {
  return (
    <div className={styles.ad}>
      <Link href={link}>
        <Image
          src={image.filename}
          alt={image.alt}
          layout="fill"
          objectFit="contain"
          objectPosition="center"
        />
        {cta_title && <div className={styles.cta}>{cta_title}</div>}
      </Link>
    </div>
  );
}
