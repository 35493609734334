import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
};

export default function IconClose(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.977 42.977"
      height={props.width}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.fill}
        d="m38.679 42.977 4.3-4.3-17.193-17.188L42.977 4.298l-4.3-4.3-17.189 17.193L4.3 0 0 4.3l17.191 17.189L0 38.677l4.3 4.3 17.188-17.191Z"
      />
    </svg>
  );
}
