// @ts-nocheck
import '../styles/globals.css';

import { apiPlugin, storyblokInit } from '@storyblok/react';
import type { NextComponentType } from 'next';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import type { FC } from 'react';
import { Fragment } from 'react';
import Popup from '@/components/stories/Popup';
import { SpeedInsights } from '@vercel/speed-insights/next';

import Advert from '@/components/stories/Advert';
import MediaText from '@/components/stories/MediaText';

type CustomNextComponent = NextComponentType & { Layout?: FC };
type CustomAppProps = AppProps & { Component: CustomNextComponent };

const components = {
  advert: Advert,
  media_text: MediaText,
};

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN,
  use: [apiPlugin],
  components,
});

function MyApp({ Component, pageProps }: CustomAppProps) {
  const Layout: CustomNextComponent | typeof Fragment = Component.Layout
    ? Component.Layout
    : Fragment;

  const consented = (status) => {
    gtag('consent', 'update', {
      ad_storage: status ? 'granted' : 'denied',
      analytics_storage: status ? 'granted' : 'denied',
    });
  };

  const cookieConsentInit = () => {
    /* eslint-disable */
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#88A537',
        },
        button: {
          background: '#105477',
        },
      },
      position: 'bottom-left',
      content: {
        href: 'https://www.officespaceintown.com/cookies-policy',
      },
      onInitialise: function () {
        consented(this.hasConsented())
      },
      onStatusChange: function () {
        consented(this.hasConsented())
      }
    });
    /* eslint-enable */
  };

  return (
    <Layout>
      <SpeedInsights />

      {process.env.NEXT_PUBLIC_NODE_ENV === 'production' && (
        <>
          <Script
            src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js"
            strategy="afterInteractive"
            onLoad={() => cookieConsentInit()}
          />

          <Script id="gtag" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}

              gtag('consent', 'default', { 'ad_storage': 'denied', 'analytics_storage': 'denied' });

              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TJJH86C');
            `}
          </Script>
        </>
      )}
      <Component {...pageProps} />
      <div id="modals" />

      {pageProps?.popups && pageProps?.popups.length > 0 && (
        <>
          {pageProps?.popups.map((popup) => (
            <Popup
              key={popup.id}
              title={popup.content.title}
              link={popup.content?.link}
              image={popup.content?.image}
              message={popup.content?.message}
            />
          ))}
        </>
      )}
    </Layout>
  );
}
export default MyApp;
